import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import InnerLayout from "../components/innerLayout"

const SuccessPage = ({ location }) => (
  <Layout>
    <SEO title="Message reçu!" />
    <InnerLayout location={location}>
      <div class="container">
        <h1>Merci. Nous vous contactons rapidement.</h1>
      </div>
    </InnerLayout>
  </Layout>
)

export default SuccessPage
